<template>
	<div>
		<div v-if="categoryFinished" class="nav pb-3">
			<div class="d-flex align-center">
				<v-btn plain rounded @click="goToParcoursDetail()">
					<v-icon class="backBtn ortho-text-gray" size="23px">mdi-arrow-left</v-icon>
				</v-btn>
				<span v-if="selectingCategory" class="ortho-ex-index-description ortho-text-gray mt-0">
					{{ selectingCategory.name }}
					<v-icon class="iconsRules" color="#2dc7ae" alt="category completed"
						>mdi-checkbox-multiple-marked-circle</v-icon
					>
				</span>

				<v-skeleton-loader v-else width="100" type="text"></v-skeleton-loader>
			</div>
			<div class="btn-report-category-container">
				<v-btn
					class="ml-md-auto btn-report-category"
					normal
					depressed
					dark
					color="#2dc7ae"
					replace
					@click="gotToChapterReport"
				>
					<v-icon left>mdi-eye</v-icon>
					<span>Rapport</span>
				</v-btn>
			</div>
		</div>
		<div v-else class="nav-not-finished pb-3">
			<v-btn plain rounded @click="goToParcoursDetail()">
				<v-icon class="backBtn ortho-text-gray" size="23px">mdi-arrow-left</v-icon>
			</v-btn>
			<span v-if="selectingCategory" class="ortho-ex-index-description ortho-text-gray mt-0">{{
				selectingCategory.name
			}}</span>
			<v-skeleton-loader v-else width="100" type="text"></v-skeleton-loader>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import { CategoryStatus } from '../../../../constants/category';
export default {
	name: 'ChapterDetailHeader',
	props: {
		selectingCategory: Object,
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		categoryFinished() {
			if (this.selectingCategory == null) return false;
			return this.selectingCategory.status === CategoryStatus.COMPLETED;
		},
	},
	methods: {
		goToParcoursDetail() {
			if (this.selectingCategory.sessions) {
				return this.$router.push(
					`/dashboard/profile/redaction/${this.$router.currentRoute.params.formationId}/${this.$router.currentRoute.params.idParcours}`
				);
			} else this.$router.push(`/dashboard/profile/${this.$router.currentRoute.params.idParcours}`);
			window.scrollTo(0, 0);
		},
		gotToChapterReport() {
			if (this.selectingCategory.sessions) {
				return this.$router.push({
					name: 'Redaction Chapter Report',
					params: { formationId: this.$router.currentRoute.params.formationId, categoryId: this.selectingCategory._id },
				});
			}

			this.$router.push({
				name: 'Report Category',
				params: {
					idFormation: this.formationProgress.formation._id,
					idCategory: this.selectingCategory._id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #f1f1f1;

	.iconsRules {
		font-size: 25px;
		transform: translateY(-2px);
	}
}
.nav-not-finished {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #f1f1f1;
}

.backBtn {
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.nav {
		flex-direction: column;
	}

	.btn-report-category-container {
		width: 100%;
		padding-right: 20px;
		padding-left: 20px;
		margin-top: 10px;
	}
	.btn-report-category {
		width: 100%;
	}
}
</style>
